import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from '@emotion/styled'
import { Global, css } from '@emotion/react'

export const breakpoints = {
    s: 576,
    m: 768,
    l: 992,
    lm: 1100,
    xlm: 1116,
    xl: 1200,
    xxl: 5200,
}

export const typo = {
    xLarge: '40px',
    large: '30px',
    regular: '20px',
    small: '15px',
    lineXLarge: '48px',
    lineLarge: '32px',
    lineRegular: '24px',
    lineSmall: '16px',
}

export const layout = {
    spacing: 8,
}

export const GlobalStyle = () => (
    <Global
        styles={css`
            html {
                -ms-text-size-adjust: 100%;
                -webkit-text-size-adjust: 100%;
                -webkit-font-smoothing: antialiased;
                text-rendering: optimizeLegibility;
            }

            body {
                margin: 0;

                font-family: Jura, -apple-system, BlinkMacSystemFont, 'Segoe UI',
                    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
                    'Droid Sans', 'Helvetica Neue', sans-serif;

                background-color: var(--white);
            }

            p,
            li {
                line-height: 1.5;
            }

            h3 {
                font-weight: 400;
                font-style: normal;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                font-size: 1.125rem;
            }

            &:focus {
                outline: none;
            }

            :root {
                --white-smoke: rgba(255, 255, 255, 0.95);
                --white: #fff;
                --black-bg: rgba(28, 29, 29, 1);
                --main-black: black;

                --white-smoke-border: rgba(255, 255, 255, 0.4);
                --main-black-border: rgba(0, 0, 0, 0.4);
                --beige: #caa98a;
            }

            .femme {
                text-transform: uppercase;
                letter-spacing: 2px;
            }

            .page-overlay {
                position: fixed;
                top: 0;
                left: 0;
                height: 100vh;
                width: 100vw;
                z-index: 15;
                background: #363636;
                -webkit-transition: opacity 0.3s ease-in-out,
                    visibility 0.3s ease-in-out;
                transition: opacity 0.3;
                z-index: 15;
            }

            .disable-scroll {
                margin: 0;
                height: 100%;
                overflow: hidden;
            }

            .hide-l {
                display: block;

                @media screen and (min-width: 992px) {
                    display: none;
                }
            }

            .show-l {
                display: none;

                @media screen and (min-width: 992px) {
                    display: block;
                }
            }

            .mobile-center {
                @media screen and (max-width: 576px) {
                    text-align: center;
                }
            }
        `}
    />
)

export const Img = styled(GatsbyImage)`
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 0.25rem;
`

export const Container = styled.div`
    margin: 0 auto;
    max-width: 1100px;
    padding: 0 0.5rem;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`

export const MainContent = styled.main`
    margin-top: 60px;
    margin-bottom: 40px;

    @media (max-width: ${breakpoints.l}px) {
        margin-top: 70px;
        margin-bottom: 20px;
    }
`

export const TwoColumnGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* gap: 1.5rem; */
    /* padding: 10px 0; */

    @media (max-width: ${breakpoints.l}px) {
        display: block;
    }
`

export const Button = styled.button`
    color: ${({ color }) => (color ? color : 'white')};
    background-color: ${({ backgroundColor }) =>
        backgroundColor ? backgroundColor : 'var(--beige)'};
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    padding: 12px 20px;
    font-size: 0.8125em;
    text-decoration: none;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    border: none;
    white-space: normal;

    &:hover {
        opacity: 0.8;
    }

    &:disabled {
        background-color: #f6f6f6;
        color: #717171;
        cursor: default;

        &:hover {
            opacity: 1;
        }
    }
`

export const Select = styled.select`
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    display: inline-block;
    box-sizing: border-box;
    padding: 0.8125rem 1rem;
    font-size: 0.8125rem;
    border: 1pt solid var(--main-black);
    border-radius: 0px;
    text-transform: uppercase;
    /* background-color: RGBA(255, 255, 255, 0); */
`

export const SmallLine = styled.hr`
    margin: 20px auto;
    border-width: 1px 0 0 0;
    width: 50px;
    clear: both;
    border-top: solid #1c1d1d;
`
