import React from 'react'
import Client from 'shopify-buy'

const client = Client.buildClient({
    domain: process.env.GATSBY_SHOP_NAME,
    storefrontAccessToken: '0137bab7d810d2f6f842adc6fc146381',
})

export const defaultStoreContext = {
    client,
    adding: false,
    checkout: {
        lineItems: [],
    },
    products: [],
    shop: {},
    addVariantToCart: () => {},
    removeLineItem: () => {},
    updateLineItem: () => {},
}

const StoreContext = React.createContext(defaultStoreContext)

export class StoreProvider extends React.Component {
    state = {
        ...defaultStoreContext,
        addVariantToCart: (variantId, quantity) => {
            return new Promise((resolve, reject) => {
                if (variantId === '' || !quantity) {
                    console.error('Both a size and quantity are required.')
                    resolve()
                }

                this.setState(state => ({
                    ...state,
                    adding: true,
                }))

                const { checkout, client } = this.state
                const checkoutId = checkout.id
                const lineItemsToUpdate = [
                    { variantId, quantity: parseInt(quantity, 10) },
                ]

                client.checkout
                    .addLineItems(checkoutId, lineItemsToUpdate)
                    .then(checkout => {
                        this.setState(state => ({
                            ...state,
                            checkout,
                            adding: false,
                        }))
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        removeLineItem: (client, checkoutID, lineItemID) => {
            return client.checkout
                .removeLineItems(checkoutID, [lineItemID])
                .then(res => {
                    this.setState(state => ({
                        ...state,
                        checkout: res,
                    }))
                })
        },
        updateLineItem: (client, checkoutID, lineItemID, quantity) => {
            const lineItemsToUpdate = [
                { id: lineItemID, quantity: parseInt(quantity, 10) },
            ]

            return client.checkout
                .updateLineItems(checkoutID, lineItemsToUpdate)
                .then(res => {
                    this.setState(state => ({
                        ...state,
                        checkout: res,
                    }))
                })
        },
    }

    async initializeCheckout() {
        // Check for an existing cart.
        const isBrowser = typeof window !== 'undefined'
        const existingCheckoutID = isBrowser
            ? localStorage.getItem('shopify_checkout_id')
            : null

        const setCheckoutInState = checkout => {
            if (isBrowser) {
                localStorage.setItem('shopify_checkout_id', checkout.id)
            }

            this.setState(state => ({
                ...state,
                checkout,
            }))
        }

        const createNewCheckout = () => this.state.client.checkout.create()
        const fetchCheckout = id => this.state.client.checkout.fetch(id)

        if (existingCheckoutID) {
            try {
                const checkout = await fetchCheckout(existingCheckoutID)

                // Make sure this cart hasn’t already been purchased.
                if (!checkout.completedAt) {
                    setCheckoutInState(checkout)
                    return
                }
            } catch (e) {
                localStorage.setItem('shopify_checkout_id', null)
            }
        }

        const newCheckout = await createNewCheckout()
        setCheckoutInState(newCheckout)
    }

    componentDidMount() {
        this.initializeCheckout()
    }

    render() {
        return (
            <StoreContext.Provider value={this.state}>
                {this.props.children}
            </StoreContext.Provider>
        )
    }
}

export default StoreContext
