import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled from '@emotion/styled'

import { LayoutProvider } from '../context/LayoutContext'
import { StoreProvider } from '../context/StoreContext'
import { GlobalStyle } from '../utils/styles'
// import Logo from './../components/Navigation/Logo'
import CookieConsent from 'react-cookie-consent'

// const comingSoon = false

// export const StyledUnderConstruction = styled.section`
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     height: 90vh;

//     h1 {
//         font-family: Jura, -apple-system, BlinkMacSystemFont, 'Segoe UI',
//             'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
//             'Helvetica Neue', sans-serif;
//         font-weight: 200;
//         font-size: 25px;
//         text-transform: uppercase;
//         letter-spacing: 5px;
//     }
// `

const StyledCookieConsent = styled.div`
    a {
        color: black;
    }
`

const Layout = ({ children }) => {
    return (
        <>
            {/* {comingSoon ? (
            <StyledUnderConstruction>
                <Logo height={45} />
                <h1>Очаквайте скоро</h1>
            </StyledUnderConstruction>
        ) : ( */}
            <StoreProvider>
                <LayoutProvider>
                    <GlobalStyle />
                    <StyledCookieConsent>
                        <CookieConsent
                            location="bottom"
                            buttonText="Приемам"
                            // declineButtonText="Decline"
                            // cookieName="gatsby-gdpr-google-analytics"
                            expires={30}
                            buttonStyle={{
                                background: 'var(--beige)',
                                color: 'var(--main-black)',
                                borderRadius: 5,
                                padding: 10,
                            }}
                            style={{
                                background: 'var(--white-smoke)',
                                color: 'var(--main-black)',
                            }}
                        >
                            Този сайт използва бисквитки. За повече информация -
                            виж&nbsp;
                            <Link to="/cookies">Бисквитки</Link>
                        </CookieConsent>
                    </StyledCookieConsent>
                    {children}
                </LayoutProvider>
            </StoreProvider>
            {/* )} */}
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
